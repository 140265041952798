import React, { Component } from "react";
import Img from "gatsby-image";

class Slider extends Component {
  state = {
    currentPic: 0
  }

  componentDidMount() {
    this.setNewRotation();
    this.divElement.style.top = ((window.innerHeight / 2) - (this.divElement.clientHeight / 2)).toString() + "px";
  }

  componentWillUnmount() {
    clearInterval(window.intervaloSlider);
  }

  setNewRotation = () => {
    window.intervaloSlider = setInterval(() => {
      let newState = {
        ...this.state
      }

      newState.currentPic++;

      if(newState.currentPic >= this.props.Array.length){
        newState.currentPic = 0;
      }

      this.setState(newState);
    }, 3000);
  }

  changePickOnClick = (index) => {
    clearInterval(window.intervaloSlider);
    this.setState({
      currentPic: index
    });
    this.setNewRotation();
  }

  render(){
    const coloresCorazon = [
      "#E19E8C",
      "#B45B66",
      "#903B5A",
      "#382F43"
    ];

    return (
      <div style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden' }}>
        
        <Img fluid={this.props.Array[this.state.currentPic]} alt="Rafa y Mon" className="imagen-slider" style={{
          height: '100%' }} />
        <div className="corazones-box" ref={ (divElement) => this.divElement = divElement}>
          {this.props.Array.map((val, index) => {
            return (
              <svg key={index} version="1.1" id={`Layer_${index}`} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 116 106" enableBackground="new 0 0 116 106" onClick={() => this.changePickOnClick(index)} >
                <path fill={coloresCorazon[index]} d="M58,17C52,7,42,0,30,0C13,0,0,13,0,30c0,33,18,38,58,76c40-38,58-43,58-76c0-17-13-30-30-30C74,0,64,7,58,17z"/>
              </svg>
            )
          })}
        </div>
      </div>
    )
  }
}

export default Slider;