import React from "react"
import { Link, graphql } from "gatsby"
import scriptLoader from 'react-async-script-loader'
import Img from "gatsby-image"
import { Slide, Fade } from 'react-reveal'

import SEO from "../components/seo"
import Slider from "../components/slider"
import menuBars from '../components/bars-solid.svg'
import MobileMenu from '../components/mobileMenu'

const index = ({ data, isScriptLoaded, isScriptLoadSucceed }) => {
  let imagesArray = [
    data.sliderImage1.childImageSharp.fluid,
    data.sliderImage2.childImageSharp.fluid,
    data.sliderImage3.childImageSharp.fluid,
    data.sliderImage4.childImageSharp.fluid
  ];

  return (
    <div className="Home">
      <SEO title="Home" />
      <header className="Home__Header">
          <div className="Home__Nav">
              <nav>
                  <ul className="desktop-menu">
                    {data.allMenuDataJson.edges.map((elemento, index) => {
                      return (
                        <li key={index}><Link to={elemento.node.path}>{elemento.node.title}</Link></li>
                      )
                    })}
                    <li className="logo-link">
                      <img srcSet={data.logoImage.childImageSharp.fluid.srcSet}
                        sizes={data.logoImage.childImageSharp.fluid.sizes}
                        src={data.logoImage.childImageSharp.fluid.src} alt="Rafa y Mon Logo" />
                    </li>
                  </ul>
                  <ul className="mobile-menu">
                    <li>
                      <img className="mobile-menu-image" src={menuBars} alt="menu bars" id="menuBars"/>
                    </li>
                    <li className="logo-link">
                      <img srcSet={data.logoImage.childImageSharp.fluid.srcSet}
                        sizes={data.logoImage.childImageSharp.fluid.sizes}
                        src={data.logoImage.childImageSharp.fluid.src} alt="Rafa y Mon Logo" />
                    </li>
                  </ul>
              </nav>
          </div>
          <Slider Array={imagesArray}/>
      </header>
      <div className="Home__Content">
        <Slide bottom>
          <div className="Home__Versiculo" style={{
            background: `url("${data.fondo.childImageSharp.fixed.src}") top left`,
            backgroundSize: "100%"
          }}>
            <p>
              Más valen dos que uno, porque obtienen más fruto de su esfuerzo.<br/>
              Si caen, el uno levanta al otro. ¡Ay del que cae y no tiene quien lo levante!<br/>
              Si dos se acuestan juntos, entrarán en calor; uno solo ¿cómo va a calentarse?<br/>
              Uno solo puede ser vencido, pero dos pueden resistir.<br/>
              ¡La cuerda de tres hilos no se rompe fácilmente!<br/><br/>
              Eclesiastés 4:9-12
            </p>
          </div>
        </Slide>
        {data.allMenuDataJson.edges.map((elemento, index) => {
          const imagenesMenu = [
            data.propuesta7.childImageSharp.fluid,
            data.propuesta8.childImageSharp.fluid,
            data.propuesta9.childImageSharp.fluid,
            data.propuesta6.childImageSharp.fluid
          ];

          return index % 2 === 0 ? (
              <Fade left key={index}>
                <Link to={elemento.node.path}>
                  <div className="Home__Box-Menu-Element">
                    <p className="Home__Box-Menu-Title" style={{backgroundColor: elemento.node.color}}>
                      {elemento.node.title}
                    </p>
                    <Img fluid={imagenesMenu[index]} alt={elemento.node.title}/>
                  </div>
                </Link>
              </Fade>
            ) : (
              <Fade right key={index}>
                <Link to={elemento.node.path}>
                  <div className="Home__Box-Menu-Element">
                    <p className="Home__Box-Menu-Title" style={{backgroundColor: elemento.node.color}}>
                      {elemento.node.title}
                    </p>
                    <Img fluid={imagenesMenu[index]} alt={elemento.node.title}/>
                  </div>
                </Link>
              </Fade>
            )
        })}
        <h1 className="Home__Hashtag">#RafaYMon</h1>
        <div className="Home__Hashtag-Container">
          <h4>¡¡QUEREMOS VER TUS FOTOS!!</h4>
          <br/>
          <p>
            Utiliza este hashtag para tus fotos de instagram y así compartirlas con todos los invitados. Todas los posts que se suban en instagram con este hashtag aparecerán automáticamente en nuestra galería para que las puedan ver durante y después del evento.
          </p>
          {isScriptLoaded && isScriptLoadSucceed && <div className="elfsight-app-43aef006-219b-4be3-9be2-7c92975f0842"></div>}
        </div>
        <footer className="Page__Footer">
          © {new Date().getFullYear()}, Powered by
          {` `}
          <a href="https://acromatico.co" target="__blank">
            <Img fixed={data.logoAcromatico.childImageSharp.fixed} alt="Logo Acromático Development" style={{
              verticalAlign: "middle"
            }}/>
          </a>
        </footer>
      </div>
      <MobileMenu />
    </div>
  )
};

export default scriptLoader("https://apps.elfsight.com/p/platform.js")(index);

export const query = graphql`
  query {
    allMenuDataJson {
      edges {
        node {
          title
          path
          color
        }
      }
    }
    logoAcromatico: file(relativePath: { eq: "acromatico-logo.png" }){
      childImageSharp {
        fixed(width: 150){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    logoImage: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fondo: file(relativePath: { eq: "fondo.png" }) {
      childImageSharp {
        fixed(width: 1024) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    propuesta6: file(relativePath: { eq: "historia/propuesta6.jpg" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    propuesta7: file(relativePath: { eq: "historia/propuesta7.jpg" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    propuesta8: file(relativePath: { eq: "historia/propuesta8.jpg" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    propuesta9: file(relativePath: { eq: "historia/propuesta9.jpg" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sliderImage1: file(relativePath: { eq: "slider-1.jpg" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sliderImage2: file(relativePath: { eq: "beso.JPG" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sliderImage3: file(relativePath: { eq: "beso-frente.JPG" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sliderImage4: file(relativePath: { eq: "besito-parisino.jpg" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
