import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"

import closeIcon from './times-solid.svg'

class MobileMenu extends Component  {
  state = {
    isOpen: false
  }

  componentDidMount(){
    document.getElementById("menuBars").addEventListener("click", () => {
      this.setState({
        isOpen: true
      });
    });
  }

  componentWillUnmount(){
    document.getElementById("menuBars").removeEventListener("click", () => {
      this.setState({
        isOpen: true
      });
    });
  }

  render(){
    return (
      <div>
        {this.state.isOpen && (
          <div className="Modal-Menu__Container" style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            zIndex: "100",
            left: "0",
            top: "0",
            width: "100%",
            height: "100vh",
            backgroundColor: "#111",
            color: "#fff"
          }}>
            <StaticQuery
              query={graphql`
                query SiteMobileMenuQuery {
                  allMenuDataJson {
                    edges {
                      node {
                        title
                        path
                        color
                      }
                    }
                  }
                  siteLogoWhite: file(relativePath: { eq: "logo-2-white.png" }) {
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              `}
              render={ data => (
                <ul style={{
                  listStyle: "none",
                  margin: "0",
                  padding: "0 10%",
                  height: "80%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <li style={{
                      padding: "20px 0",
                      width: "100%",
                      textAlign: "center"
                    }}>
                    <Link to="/"><img srcSet={data.siteLogoWhite.childImageSharp.fluid.srcSet}
                      sizes={data.siteLogoWhite.childImageSharp.fluid.sizes}
                      src={data.siteLogoWhite.childImageSharp.fluid.src} alt="Rafa y Mon Logo" style={{
                        maxWidth: "100%",
                        maxHeight: "75px"
                      }}/></Link>
                  </li>
                  {data.allMenuDataJson.edges.map((elemento, index) => {
                    return (
                      <li key={index} style={{
                        padding: "10px 0",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "0.7em"
                      }}>
                        <Link to={elemento.node.path} style={{
                          fontSize: "1.5em",
                          color: "#ffffff"
                        }}>{elemento.node.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              )}
            />
            <img src={closeIcon} alt="close" style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width:"15px"
            }} onClick={() => this.setState({isOpen: false})}/>
          </div>
        )}
      </div>
    )
  }
}

MobileMenu.propTypes = {
  siteTitle: PropTypes.string,
}

MobileMenu.defaultProps = {
  siteTitle: ``,
}

export default MobileMenu;
